<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
              :login="login"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <list class="wa__large mt-9">
        <div class="gallery__box">
          <div class="wa__drop__image">
            <dropzone :model-id="modelId" @success="addImageToList" />
          </div>
          <!-- <v-row class="mt-4 wa__gallery">
            <v-col
              v-for="image in images"
              :key="image.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              class="pr-3 pr-md-3"
            >
              <GalleryImage :image="image" :images="images" />
            </v-col>
          </v-row> -->
          <div class="d-flex justify-left items mt-4">
            <div
              v-for="image in images"
              :key="image.id"
              class="pr-sm-3 mt-3 mt-sm-0 item"
            >
              <GalleryImage :image="image" :images="images" />
            </div>
          </div>
          <div class="mt-6" v-if="role === 'manager'">
            <SectionTitle title="PDF Imagaes" />
            <div class="d-flex justify-left items mt-4">
              <div
                v-for="image in images"
                :key="image.id"
                class="pr-sm-3 mt-3 mt-sm-0 item"
              >
                <GalleryImage :image="image" :images="images" />
              </div>
            </div>
          </div>
        </div>
      </list>
    </main>
  </div>
</template>
<script>
import UserRepository from "@/abstraction/repository/userRepository";
import { UserStorage } from "@/utils/storage";
import Dropzone from "@/components/Global/Input/Dropzone";
import GalleryImage from "@/components/Global/Section/GalleryImage";
import SectionTitle from "@/components/Global/Section/SectionTitle.vue";
export default {
  components: { Dropzone, GalleryImage, SectionTitle },
  data() {
    return {
      role: UserStorage.get() ? UserStorage.get().type : "client",
      login: true,
      modelId: null,
      images: [],
    };
  },
  methods: {
    async loadMyImages() {
      let repository = new UserRepository();
      const user = await repository.myInfo();
      this.images = user.model.galleries;
      this.modelId = user.model.id;
    },
    addImageToList(file) {
      file["image"] = file.url;
      file["thumbnail"] = file.url;
      this.images.push(file);
    },
  },
  created() {
    this.loadMyImages();
  },
};
</script>
<style scoped>
.wa__drop__image {
  background-color: var(--color-bg-gray);
  position: relative;
  padding: 80px;
  border: 1px dashed var(--color-gray);
  border-radius: 6px;
}
.wa__drop__image--text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wa__drop__image--text div:first-child {
  font-family: "Montserrat";
  font-size: 25px;
  letter-spacing: 7px;
  font-size: 25px;
  text-transform: uppercase;
}
.wa__drop__image--text div:last-child {
  font-family: "Montserrat-regular";
  text-align: center;
  letter-spacing: 7px;
  letter-spacing: 0;
  color: var(--color-gray);
}
.gallery__box {
  padding: 30px 20px;
}
.wa__gallery col {
  max-width: 14.666667% !important;
}
.items {
  flex-wrap: wrap;
}
.items .item {
  width: 16.6666666667%;
  margin-bottom: 20px;
}
.items .item:nth-child(6n) {
  padding: 0 !important;
}
@media (max-width: 1200px) {
  .items .item {
    width: 20%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(5n) {
    padding: 0 !important;
  }
  .items .item:nth-child(6n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 960px) {
  .items .item {
    width: 25%;
    margin-bottom: 20px;
  }

  .items .item:nth-child(4n) {
    padding: 0 !important;
  }
  .items .item:nth-child(5n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 768px) {
  .items .item {
    width: 33.33333%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(3n) {
    padding: 0 !important;
  }
  .items .item:nth-child(4n) {
    padding-right: 12px !important;
  }
  .wa__drop__image {
    padding: 45px;
  }
}
@media (max-width: 480px) {
  .items {
    justify-content: center;
  }
  .items .item {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 !important;
  }
  .items .item:nth-child(3n),
  .items .item:nth-child(4n),
  .items .item:nth-child(5n),
  .items .item:nth-child(6n) {
    padding-right: 0 !important;
  }
  .wa__drop__image {
    padding: 15px;
  }
  .gallery__box {
    padding: 30px 10px;
  }
}
</style>
